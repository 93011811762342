import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { Checkbox } from '../../../components/Checkbox';
import { colors } from '../../../constants/Colors';
import { TypesButton } from '../../../constants/ButtonTypes';
import { SizesButton } from '../../../constants/SizeButton';
import { routes } from '../../../constants/RouterPath';
import { PageTitle } from '../../../components/PageTitle';
import { UploadPhotoWithMiniature } from '../../../components/UploadPhotoWithMiniature';
import { createSaleBreadcrumbs } from '../../../constants/BreadCrumbs/createSaleBreadcrumbs';
import Switch from '../../../components/sw';
import { CopyToClipboard } from '../../../components/CopyToClipboard';
import { TypesIcon } from '../../../types/TypesIcon';
import { Icon } from '../../../components/Icon';
import { SubmitButtons } from '../../../components/StyledComponents';
import { CreatePromotionsContract } from '../../../types/Endpoints/sales/PromitionsContracts';
import { UploadFile } from '../../../types/Endpoints/files/FilesContract';
import { createPromotionsThink } from '../../../actions/sales/thunks/createPromotions';
import { AddProductsPromotions } from '../../../components/AddProductsToPromotions';
import { config } from '../../../config';
import { isEqualObject } from '../../../helpers/isEqual';
import { addNotification } from '../../../actions/notifications';
import { TypesSnackbars } from '../../../types/TypesSnackbars';
import { Textarea } from '../../../components/TextArea';
import { PageTitles } from '../../../constants/PageTitles';
import { getIsoDate, getIsoDateTime } from '../../../helpers/date/getIsoDate';
import * as S from './styles';

const PATH = `${config.frontStageUrl}stock/`;

export const CreateSale = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState(false);
    const [isShownWhileActiveState, setIsShownWhileActiveState] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState<{ id: number }[]>(
        [],
    );
    const [selectedProductsInModal, setSelectedProductsInModal] = useState<
        { id: number }[]
    >([]);
    const [_, rerender] = useState<boolean>(false);
    const saveAndOffDrawer = () => {
        setState(false);
        setSelectedProducts(selectedProductsInModal);
    };

    const onDrawer = () => {
        setState(true);
        setSelectedProductsInModal(selectedProducts);
    };

    const offDrawer = () => {
        setState(false);
        setSelectedProductsInModal([]);
    };

    const handleSubmit = (
        values: CreatePromotionsContract<UploadFile>['requestBody'],
    ) => {
        const productsData = selectedProducts.map(product => ({ id: product.id }));

        const requestData: CreatePromotionsContract<number | null>['requestBody'] = {
            ...values,
            urn: values.urn.replace(PATH, ''),
            seoH1: values.seoH1,
            seoTitle: values.seoTitle,
            seoDescription: values.seoDescription,
            headerImageFileId: values.headerImageFileId.id || null,
            headerImageMobileFileId: values.headerImageMobileFileId.id || null,
            isShownWhileActive: values.isShownWhileActive,
            startDate:
            isShownWhileActiveState ? getIsoDate(true, values.activeStartDate ? new Date(values.activeStartDate) : new Date()) as string
                : getIsoDate(true, values.startDate ? new Date(values.startDate) : new Date()) as string,
            endDate:
                isShownWhileActiveState ? getIsoDate(true, values.activeEndDate ? new Date(values.activeEndDate) : new Date()) as string
                    : getIsoDate(true, values.endDate ? new Date(values.endDate) : new Date()) as string,
            activeStartDate: getIsoDateTime(values.activeStartDate ? new Date(values.activeStartDate) : new Date()) as string,
            activeEndDate: getIsoDateTime(values.activeEndDate ? new Date(values.activeEndDate) : '') ? getIsoDateTime(values.activeEndDate ? new Date(values.activeEndDate) : '') as string : null,
            bannerStatus: {
                status: values.bannerStatus.status,
                buttonText: values.bannerStatus.buttonText,
            },
            // bannerStatusInCatalogue: {
            //     status: values.bannerStatusInCatalogue?.status || 'ACTIVE',
            //     buttonText: values.bannerStatusInCatalogue?.buttonText || 'Активна',
            // },
            siteBannerDesktopImageFileId: values.siteBannerDesktopImageFileId.id || null,
            siteBannerMobileImageFileId: values.siteBannerMobileImageFileId.id || null,
            mobileBannerDesktopImageFileId:
                values.mobileBannerDesktopImageFileId.id || null,
            mobileBannerMobileImageFileId:
                values.mobileBannerMobileImageFileId.id || null,
            products: productsData,
        };
        if (values.bannerStatus?.desktopImageFileId?.id) {
            requestData.bannerStatus.desktopImageFileId = values.bannerStatus.desktopImageFileId.id;
        }
        if (values.bannerStatus?.mobileImageFileId?.id) {
            requestData.bannerStatus.mobileImageFileId = values.bannerStatus.mobileImageFileId.id;
        }
        if (values.bannerStatus?.order) {
            requestData.bannerStatus.order = +values.bannerStatus.order;
        }

        // if (values.bannerStatusInCatalogue?.desktopImageFileId?.id) {
        //     requestData.bannerStatusInCatalogue.desktopImageFileId = values.bannerStatusInCatalogue.desktopImageFileId.id;
        // }
        // if (values.bannerStatusInCatalogue?.mobileImageFileId?.id) {
        //     requestData.bannerStatusInCatalogue.mobileImageFileId = values.bannerStatusInCatalogue.mobileImageFileId.id;
        // }

        delete requestData.bannerStatusInCatalogue;

        dispatch(createPromotionsThink(requestData));
    };

    const validationSchema = yup.object().shape({
        title: yup
            .string()
            .max(50, 'Не более 50 символов')
            .required('Поле является обязательным'),
        urn: yup
            .string()
            .test(
                'exception',
                'Поле является обязательным',
                value => !PATH.includes(value || ''),
            ),
        activeStartDate: yup
            .date()
            .required('Поле является обязательным'),
        activeEndDate: yup
            .date()
            .nullable()
            .transform((value, originalValue) => (originalValue ? new Date(originalValue) : value))
            .min(
                yup.ref('activeStartDate'),
                'Конец публикации не должен быть раньше начала публикации',
            )
            .typeError('Неверный формат даты'),
        endDate: yup
            .date()
            .min(
                yup.ref('startDate'),
                'Конец действия акции не должен быть раньше начала действия акции',
            )
        ,
    });

    const initialValues = {
        globalStatus: 'ACTIVE',
        title: '',
        urn: PATH,
        seoH1: '',
        seoDescription: '',
        seoTitle: '',
        isShownWhileActive: isShownWhileActiveState,
        activeStartDate: '',
        activeEndDate: '',
        startDate: '',
        endDate: '',
        shortDescription: '',
        headerImageFileId: 1,
        headerImageMobileFileId: 1,
        bannerStatus: {
            status: 'INACTIVE',
            buttonText: 'Подробнее',
        },
        bannerStatusInCatalogue: {
            status: 'INACTIVE',
            buttonText: 'Подробнее',
        },
        products: [],
        additionalDescription: '',
        isShowOnWeb: false,
        isShowOnMobile: false,
        siteBannerDesktopImageFileId: 1,
        siteBannerMobileImageFileId: 1,
        mobileBannerDesktopImageFileId: 1,
        mobileBannerMobileImageFileId: 1,
    };

    const form = useFormik<CreatePromotionsContract<UploadFile>['requestBody']>({
        onSubmit: handleSubmit,
        initialValues:
            initialValues as CreatePromotionsContract<UploadFile>['requestBody'],
        validationSchema,
    });

    const handleToggle1 = (value: 'ACTIVE' | 'INACTIVE') => {
        form.values.bannerStatus.status = value;
        rerender(prev => !prev);
    };

    const handleResetForm = (
        values: CreatePromotionsContract<UploadFile>['requestBody'],
    ) => {
        history.push(routes.sales);
        const equal = isEqualObject(initialValues, values);
        if (!equal) {
            dispatch(
                addNotification({
                    type: TypesSnackbars.ERROR,
                    message: {
                        title: 'Изменения отменены',
                        description: '',
                    },
                    icon: TypesIcon.SNACK_ERROR,
                }),
            );
        }
    };

    return (
        <S.RootWrapper>
            <S.Wrapper>
                <PageTitle>{PageTitles.SALES}</PageTitle>
                <Breadcrumbs breadcrumbs={createSaleBreadcrumbs('Добавить')} />
                <S.Root>
                    <S.MainWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>Отображение акции</S.Subtitle>
                            <Switch
                                name="isActive"
                                value={form.values.globalStatus === 'ACTIVE'}
                                onChange={() => {
                                    form.values.globalStatus = form.values.globalStatus === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE';
                                    rerender(prev => !prev);
                                }}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        <S.IdInfo>SEO</S.IdInfo>
                        <Textarea
                            name="seoH1"
                            onChange={form.handleChange}
                            value={form.values.seoH1}
                            placeholder="H1"
                            maxLength={255}
                            title="H1"
                            isTouched={form.touched.seoH1}
                            error={form.errors.seoH1}
                        />
                        <Textarea
                            name="seoTitle"
                            onChange={form.handleChange}
                            value={form.values.seoTitle}
                            placeholder="Title"
                            maxLength={255}
                            title="Title"
                            isTouched={form.touched.seoTitle}
                            error={form.errors.seoTitle}
                        />
                        <Textarea
                            name="seoDescription"
                            onChange={form.handleChange}
                            value={form.values.seoDescription}
                            placeholder="Description"
                            maxLength={255}
                            title="Description"
                            isTouched={form.touched.seoDescription}
                            error={form.errors.seoDescription}
                        />
                    </S.MainWrapper>
                    <S.CheckboxWrapper>
                        <S.IdInfo>Основное</S.IdInfo>
                        <S.StyledInput
                            name="title"
                            onChange={form.handleChange}
                            value={form.values.title}
                            placeholder="Заголовок (не более 50 символов)*"
                            typeInput="text"
                            maxLength={255}
                            title="Заголовок (не более 50 символов)*"
                            isTouched={form.touched.title}
                            error={form.errors.title}
                            count={form.submitCount}
                        />

                        <S.UrlWrapper>
                            <S.InputWithPlaceholder
                                name="urn"
                                onChange={form.handleChange}
                                value={form.values.urn.length < PATH.length ? PATH : form.values.urn}
                                typeInput="text"
                                title="URL*"
                                isTouched={form.touched.urn}
                                error={form.errors.urn}
                                count={form.submitCount}
                            />
                            <S.CopyWrapper>
                                <CopyToClipboard copyText={form.values.urn.length < PATH.length ? PATH : form.values.urn} />
                            </S.CopyWrapper>
                        </S.UrlWrapper>

                        <S.CheckboxWrapper>
                            <Checkbox
                                name="isShownWhileActive"
                                title="Активность = сроку действия"
                                checked={form.values.isShownWhileActive}
                                onChange={res => {
                                    setIsShownWhileActiveState(prev => !prev);
                                    form.handleChange(res);
                                }}
                            />
                        </S.CheckboxWrapper>
                        <S.DateWrapper>
                            <S.StyledInput
                                name="activeStartDate"
                                onChange={form.handleChange}
                                value={
                                    typeof form.values.activeStartDate === 'string'
                                        ? form.values.activeStartDate
                                        : ''
                                }
                                placeholder="Начало публикации"
                                typeInput="datetime-local"
                                title="Начало публикации"
                                isTouched={form.touched.activeStartDate}
                                error={form.errors.activeStartDate}
                                count={form.submitCount}
                            />

                            <S.StyledInput
                                name="activeEndDate"
                                onChange={form.handleChange}
                                value={
                                    typeof form.values.activeEndDate === 'string'
                                        ? form.values.activeEndDate
                                        : ''
                                }
                                placeholder="Конец публикации"
                                typeInput="datetime-local"
                                title="Конец публикации"
                                isTouched={form.touched.activeEndDate}
                                error={form.errors.activeEndDate}
                                count={form.submitCount}
                            />
                        </S.DateWrapper>

                        {
                            !isShownWhileActiveState
                        && (
                            <S.DateWrapper>
                                <S.StyledInput
                                    name="startDate"
                                    onChange={form.handleChange}
                                    value={
                                        typeof form.values.startDate === 'string'
                                            ? form.values.startDate
                                            : ''
                                    }
                                    placeholder="Дата начала"
                                    typeInput="date"
                                    title="Дата начала"
                                    isTouched={form.touched.startDate}
                                    error={form.errors.startDate}
                                />

                                <S.StyledInput
                                    name="endDate"
                                    onChange={form.handleChange}
                                    value={
                                        typeof form.values.endDate === 'string'
                                            ? form.values.endDate
                                            : ''
                                    }
                                    placeholder="Дата окончания"
                                    typeInput="date"
                                    title="Дата окончания"
                                    isTouched={form.touched.endDate}
                                    error={form.errors.endDate}
                                />
                            </S.DateWrapper>
                        )
                        }

                        <S.StyledInput
                            name="shortDescription"
                            onChange={form.handleChange}
                            value={form.values.shortDescription}
                            placeholder="Краткое описание (не более 60 символов)"
                            typeInput="text"
                            maxLength={255}
                            title="Краткое описание (не более 60 символов)"
                            isTouched={form.touched.shortDescription}
                            error={form.errors.shortDescription}
                        />
                    </S.CheckboxWrapper>
                    <S.MainWrapper>
                        <S.Subtitle>Баннер в списке акций на сайте</S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="headerImageFileId"
                            title="Изображение для desktop версии"
                            format="7:2.6"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageFileId}
                            maxSize={3}
                        />
                        <UploadPhotoWithMiniature
                            name="headerImageMobileFileId"
                            title="Изображение для мобильной версии"
                            format="3.5:2"
                            onChange={form.setFieldValue}
                            value={form.values.headerImageMobileFileId}
                            maxSize={3}
                        />
                    </S.MainWrapper>

                    <S.CheckboxWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>Статус отображения в баннере</S.Subtitle>
                            <Switch
                                name="isShowOnBanner"
                                value={form.values.bannerStatus.status === 'ACTIVE'}
                                onChange={() => handleToggle1(
                                    form.values.bannerStatus.status === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE',
                                )}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        {form.values.bannerStatus.status === 'ACTIVE' && (
                            <>
                                <S.StyledInput
                                    name="bannerStatus.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatus.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatus?.buttonText}
                                    error={form?.errors?.bannerStatus?.buttonText}
                                />

                                <S.StyledInput
                                    name="bannerStatus.order"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatus.order}
                                    placeholder="Сортировка"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Сортировка"
                                    isTouched={form?.touched?.bannerStatus?.order}
                                    error={form?.errors?.bannerStatus?.order}
                                />

                                <UploadPhotoWithMiniature
                                    name="bannerStatus.desktopImageFileId"
                                    title="Изображение для desktop версии"
                                    format="9.5:4"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.desktopImageFileId}
                                    maxSize={4}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatus.mobileImageFileId"
                                    title="Изображение для мобильной версии"
                                    format="2:1"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatus.mobileImageFileId}
                                    maxSize={3}
                                />
                            </>
                        )}
                    </S.CheckboxWrapper>

                    <S.CheckboxWrapper>
                        <S.FlexWrapper>
                            <S.Subtitle>
                                Статус отображения в баннере в «Каталоге»
                            </S.Subtitle>
                            <S.Dicription>
                                Для загрузки в «Каталоге» доступна только одна акция
                            </S.Dicription>
                            <Switch
                                name="isShowOnBannerCatalog"
                                value={form.values.bannerStatusInCatalogue?.status === 'ACTIVE'}
                                onChange={() => {
                                    form.values.bannerStatusInCatalogue.status = form.values.bannerStatusInCatalogue?.status === 'ACTIVE'
                                        ? 'INACTIVE'
                                        : 'ACTIVE';
                                    rerender(prev => !prev);
                                }}
                                type="checkbox"
                                title
                            />
                        </S.FlexWrapper>

                        {form.values.bannerStatusInCatalogue?.status === 'ACTIVE' && (
                            <S.CheckboxWrapper>
                                <S.StyledInput
                                    name="bannerStatusInCatalogue.buttonText"
                                    onChange={form.handleChange}
                                    value={form.values.bannerStatusInCatalogue?.buttonText}
                                    placeholder="Текст кнопки"
                                    typeInput="text"
                                    maxLength={255}
                                    title="Текст кнопки"
                                    isTouched={form?.touched?.bannerStatusInCatalogue?.buttonText}
                                    error={form.errors.bannerStatusInCatalogue?.buttonText}
                                />

                                <UploadPhotoWithMiniature
                                    name="bannerStatusInCatalogue.desktopImageFileId"
                                    title="Баннер в каталог для desktop версии сайта"
                                    format="6:1"
                                    onChange={form.setFieldValue}
                                    value={
                                        form.values.bannerStatusInCatalogue?.desktopImageFileId
                                    }
                                    maxSize={3}
                                />
                                <UploadPhotoWithMiniature
                                    name="bannerStatusInCatalogue.mobileImageFileId"
                                    title="Баннер в каталог  для мобильной версии сайта"
                                    format="2:1.5"
                                    onChange={form.setFieldValue}
                                    value={form.values.bannerStatusInCatalogue?.mobileImageFileId}
                                    maxSize={3}
                                />
                            </S.CheckboxWrapper>
                        )}
                    </S.CheckboxWrapper>

                    <S.MainWrapper>
                        <S.StyledTextEditor
                            name="additionalDescription"
                            onChange={form.setFieldValue}
                            valueEditor={form.values.additionalDescription}
                            title="Дополнительное описание"
                            withoutPictures={false}
                        />
                    </S.MainWrapper>

                    <S.CheckboxWrapper>
                        <S.AddButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={onDrawer}
                        >
                            <Icon
                                type={TypesIcon.PLUS_BIG}
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                color={colors.greyDark}
                            />
                            Добавить товары
                        </S.AddButton>
                        <S.CountInfo>{`Добавлено ${selectedProducts.length} товаров в акцию`}</S.CountInfo>
                    </S.CheckboxWrapper>

                    <S.CheckboxWrapper>
                        <S.IdInfo>Отображение</S.IdInfo>
                        <Checkbox
                            name="isShowOnWeb"
                            title="Отображать на сайте"
                            checked={form.values.isShowOnWeb}
                            onChange={form.handleChange}
                        />

                        <Checkbox
                            name="isShowOnMobile"
                            title="Отображать в мобильном приложении"
                            checked={form.values.isShowOnMobile}
                            onChange={form.handleChange}
                        />
                    </S.CheckboxWrapper>
                    <S.CheckboxWrapper>
                        <S.Subtitle>Баннер внутри акции на сайте</S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="siteBannerDesktopImageFileId"
                            title="Изображение для desktop версии"
                            format="6:1"
                            onChange={form.setFieldValue}
                            value={form.values.siteBannerDesktopImageFileId}
                            maxSize={4}
                        />
                        <UploadPhotoWithMiniature
                            name="siteBannerMobileImageFileId"
                            title="Изображение для мобильной версии"
                            format="3:1"
                            onChange={form.setFieldValue}
                            value={form.values.siteBannerMobileImageFileId}
                            maxSize={3}
                        />
                    </S.CheckboxWrapper>

                    <S.CheckboxWrapper>
                        <S.Subtitle>
                            Баннер к акции в мобильном приложении
                        </S.Subtitle>
                        <UploadPhotoWithMiniature
                            name="mobileBannerDesktopImageFileId"
                            title="Баннер в списке акций"
                            format="2:1"
                            onChange={form.setFieldValue}
                            value={form.values.mobileBannerDesktopImageFileId}
                            maxSize={3}
                        />
                        <UploadPhotoWithMiniature
                            name="mobileBannerMobileImageFileId"
                            title="Баннер внутри акции "
                            format="2.5:1"
                            onChange={form.setFieldValue}
                            value={form.values.mobileBannerMobileImageFileId}
                            maxSize={3}
                        />
                    </S.CheckboxWrapper>
                </S.Root>

                <S.StyledDrawer anchor="right" open={state} onClose={offDrawer}>
                    <S.DrawerRoot>
                        <S.Heading>
                            <S.ModalTitle>Добавить товары</S.ModalTitle>
                            <S.CloseButton onClick={offDrawer}>
                                <Icon
                                    type={TypesIcon.CLOSE_BIG}
                                    color={colors.grey20}
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                />
                            </S.CloseButton>
                        </S.Heading>
                        <S.ModalDescription>{`Добавлено ${selectedProductsInModal.length} товаров в акцию`}</S.ModalDescription>
                        <AddProductsPromotions
                            selectedProducts={selectedProductsInModal}
                            setSelectedProducts={setSelectedProductsInModal}
                        // offSecondDrawer={offSecondDrawer}
                        />

                        <S.ButtonWrapper>
                            <S.StyledButton
                                typeButton={TypesButton.SECONDARY}
                                size={SizesButton.M}
                                onClick={offDrawer}
                            >
                                Отменить
                            </S.StyledButton>
                            <S.StyledButton
                                typeButton={TypesButton.PRIMARY}
                                size={SizesButton.M}
                                onClick={saveAndOffDrawer}
                            >
                                Сохранить изменения
                            </S.StyledButton>
                        </S.ButtonWrapper>
                    </S.DrawerRoot>
                </S.StyledDrawer>
            </S.Wrapper>
            <SubmitButtons
                reset={() => handleResetForm(form.values)}
                submit={form.submitForm}
            />
        </S.RootWrapper>
    );
};
