import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontWeight } from '../../constants/Styles/fontWeight';
import { units } from '../../helpers/styles/units';
import { EditButton } from '../Button/editButton';
import { TypesButton } from '../../constants/ButtonTypes';
import { SizesButton } from '../../constants/SizeButton';
import { routes } from '../../constants/RouterPath';
import { GetPromotionsContract } from '../../types/Endpoints/sales/PromitionsContracts';
import { CopyToClipboard } from '../CopyToClipboard';
import { config } from '../../config';
import { colors } from '../../constants/Colors';
import { EmptyRow } from '../EmptyRow';

const styles = {
    root: {
        borderRadius: 0,
        boxShadow: 'none',
        '& .MuiTableHead-root': {
            background: colors.grey60,
            borderRadius: 0,
        },
        '& .MuiTableContainer-root': {
            borderRadius: 0,
        },
        '& .MuiPaper-root': {
            borderRadius: '0px !important',
        },

        '& . MuiTableCell-root': {
            borderBottom: 0,
        },
    },
    body: {
        '& .MuiTableRow-root:hover': {
            backgroundColor: 'white',
        },
        '& .text-grey': {
            color: colors.grey20,
            maxWidth: '224px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
            paddingBottom: '22px',
        },
        '& .cell-medium': {
            fontWeight: FontWeight.BOLD,
        },
    },
};

const StyledClipboardWrapper = styled.div`
  position: relative;
  top: ${units(3)};
  position: relative;
  display: inline-block;
  height: ${units(12)};
  width: ${units(12)};
  margin-right: ${units(5)};
`;

const StyledCell = styled(TableCell)`
  .status {
    display: flex;
    padding: ${units(2)} ${units(6)};
    align-items: center;
    gap: ${units(4)};
  }

  & .active {
    background-color: rgba(87, 198, 118, 0.05);

    & div {
      background-color: ${colors.green};
    }
  }

  & .end {
    background-color: rgba(231, 72, 72, 0.05);

    & div {
      background-color: ${colors.red};
    }
  }

  & .plan {
    background-color: rgba(215, 180, 106, 0.05);

    & div {
      background-color: ${colors.gold};
    }
  }

  & .in_catalog {
    background-color: ${colors.grey60};

    & div {
      background-color: ${colors.greyDark};
    }
  }
`;

const StyledStatusInfo = styled.div`
  min-width: ${units(4)};
  min-height: ${units(4)};
`;

const StyledDiv = styled.div`
  display: flex;
  padding: ${units(2)} ${units(6)};
  align-items: center;
`;

const StyledEditButton = styled(EditButton)`
  margin: 0 auto;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${units(5)};
`;

export const StatusActiveComponent = () => (
    <StyledDiv className="status active">
        <StyledStatusInfo />
        <span>Опубликована</span>
    </StyledDiv>
);

export const StatusDisabledComponent = () => (
    <StyledDiv className="status end">
        <StyledStatusInfo />
        <span>Завершена</span>
    </StyledDiv>
);

export const StatusBlockedComponent = () => (
    <StyledDiv className="status plan">
        <StyledStatusInfo />
        <span>Запланирована</span>
    </StyledDiv>
);

export enum StatusSales {
  ACTIVE = 'Published',
  PLANING = 'Planned',
  END = 'Ended',
}

const StatusList = {
    [StatusSales.ACTIVE]: <StatusActiveComponent />,
    [StatusSales.END]: <StatusDisabledComponent />,
    [StatusSales.PLANING]: <StatusBlockedComponent />,
};

const getStatusComponent = (status: StatusSales) => StatusList[status];

export const SalesTable = ({
    promotionsList,
}: {
  promotionsList: GetPromotionsContract['requestBody'];
}): JSX.Element => {
    const history = useHistory();
    const [rows, setRows] = useState<GetPromotionsContract['requestBody']>([]);

    useEffect(() => {
        const data = [...promotionsList].sort((prev, next) => {
            if (prev.bannerStatus.order > next.bannerStatus.order) {
                return 1;
            }
            if (prev.bannerStatus.order < next.bannerStatus.order) {
                return -1;
            }
            return 0;
        });

        setRows(data);
    }, [promotionsList]);

    const parseDate = (date: string) => date.slice(0, 10).split('-').reverse().join('.');

    /* const handleDragEnd = (e: any) => {
        if (!e.destination) return;
        let tempData = Array.from(rows);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setRows(tempData);
    }; */

    /* const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
        } else {
            rows.forEach((row, index) => {
                if (row.bannerStatus.order !== index + 1) {
                    dispatch(
                        updatePromotionsThink({
                            data: { bannerStatus: { order: index + 1 } },
                            saleId: row.id.toString(),
                        }),
                    );
                }
            });
        }
    }, [rows]); */

    return (
        <TableContainer component={Paper} sx={styles.root}>
            <Table sx={{ minWidth: '100%' }} aria-label="simple table">
                <TableHead sx={styles.root}>
                    <TableRow
                        sx={{
                            'td, th': { border: 0, fontWeight: FontWeight.BOLD },
                        }}
                    >
                        <TableCell>URL</TableCell>
                        <TableCell align="left">Заголовок</TableCell>
                        <TableCell align="left">Дата начала</TableCell>
                        <TableCell align="left">Дата окончания</TableCell>
                        <TableCell align="left">Статус</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody sx={[styles.root, styles.body]}>
                    {rows
            && rows.map(row => (
                <TableRow key={row.id} sx={{ 'td, th': { border: 0 } }}>
                    <TableCell
                        component="th"
                        scope="row"
                        className="text-grey"
                        sx={{ width: '25%' }}
                    >
                        <StyledClipboardWrapper>
                            <CopyToClipboard
                                copyText={`${config.frontStageUrl}stock/${row.urn}`}
                            />
                        </StyledClipboardWrapper>
                        <span>{`${config.frontStageUrl}stock/${row.urn}`}</span>
                    </TableCell>
                    <TableCell align="left" sx={{ width: '25%' }}>
                        {' '}
                        {row.title}
                    </TableCell>

                    <TableCell align="left" sx={{ width: '15%' }}>
                        {parseDate(row.startDate)}
                    </TableCell>
                    <TableCell align="left" sx={{ width: '15%' }}>
                        {parseDate(row.endDate)}
                    </TableCell>

                    <StyledCell align="left" sx={{ width: '15%' }}>
                        <StatusWrapper>
                            {getStatusComponent(row.status as StatusSales)}
                        </StatusWrapper>
                    </StyledCell>
                    <TableCell align="left" sx={{ width: '5%' }}>
                        <StyledEditButton
                            typeButton={TypesButton.SECONDARY}
                            size={SizesButton.M}
                            onClick={() => history.push(`${routes.edit_sale}/${row.id}`)}
                        />
                    </TableCell>
                </TableRow>
            ))}
                    {rows && rows.length === 0 && <EmptyRow colSpan={6} />}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
