export const getIsoDate = (isStartDate: boolean, dateString?: Date | string): string | undefined => {
    if (dateString) {
        const date = new Date(dateString);
        date.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());

        if (isStartDate) {
            date.setUTCHours(0, 0, 0, 0);
        } else {
            date.setUTCHours(23, 59, 59, 999);
        }

        return date.toISOString();
    }
};

export const getIsoDateTime = (dateString?: string | Date): string | undefined => {
    if (dateString) {
        const date = new Date(dateString);

        return date.toISOString();
    }
};

export const localizeIsoDate = (isoString: string): string => {
    const date = new Date(isoString);

    const localizedDate = new Date(date.getTime() + 3 * 60 * 60 * 1000);

    const isoLocalString = localizedDate.toISOString();
    return isoLocalString.slice(0, 16);
};
