export const routes = {
    login: '/login',
    catalog: '/catalog',
    dashboard: '/dashboard',
    goods: '/goods',
    goods_edit: '/goods/edit',
    goods_add: '/goods/add',
    home: '/home',
    logs: '/logs',
    handbook: '/handbook',
    notes: '/notes',
    notifications: '/notifications',
    orders: '/orders',
    pages: '/pages',
    shops: '/shops',
    users: '/users',
    docs: '/docs',
    installment_and_credit: '/installment-and-credit',
    purchase_and_exchange: '/purchase-and-exchange',
    payment_and_delivery: '/payment-and-delivery',
    loyalty_program: '/loyalty-program',
    watch_service: '/watch-service',
    profile: '/profile',
    settings: '/settings',
    filters: '/filters',
    edit_filter: '/filters/edit',
    create_filter: '/filters/create',
    collections: '/collections',
    brands: '/brands',
    certificates: '/certificates',
    sales: '/sales',
    create_sale: '/sales/create',
    edit_sale: '/sales/edit',
    blog: '/blog',
    create_blog: '/blog/create',
    edit_blog: '/blog/edit',
    create_user: '/users/create',
    edit_user: '/users/edit',
    create_shop: '/shops/create',
    edit_category: '/settings/edit',
    create_category: '/settings/create',
    create_collection: '/collections/create',
    edit_collection: '/collections/edit',
    create_brand: '/brands/create',
    edit_brand: '/brands/edit',
    edit_shop: '/shops/edit',
    site_body: '/site-body',
    mobile_body: '/mobile-body',
    search_settings: '/search-settings',
    search_settings_edit: '/search-settings/edit',
    search_settings_create: '/search-settings/create',
    footer: '/footer',
    order_list: '/order-list',
    edit_order: '/order-list/edit',
    order_settings: '/order-settings',
    menu: '/menu',
    edit_menu: '/menu/edit',
    filter_cloud: '/filter-cloud',
    edit_notifications: '/notifications/edit',
    create_notifications: '/notifications/create',
    edit_filter_cloud: '/filter-cloud/edit',
    robots: '/robots',
};
