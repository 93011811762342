import { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageTitle } from '../../../components/PageTitle';
import { Breadcrumbs } from '../../../components/Breadcrumbs';
import { getPage } from '../../../actions/dinamicPages';
import { updateWatchServicePage } from '../../../actions/dinamicPages/thunks/updateWatchServicePage';
import { DynamicPagesKeys } from '../../../types/Models/DinamicPages';
import { selectDinamicPage } from '../../../selectors/dinamicPages';
import { useTypeSelector } from '../../../store';
import { DynamicPageForm } from '../../../components/DynamicPageForm';
import * as S from '../styles';
import { PageTitles } from '../../../constants/PageTitles';
import { WatchServiceBreadcrumbs } from '../../../constants/BreadCrumbs/watchServiceBreadcrumbs';

const FORMATS_SERVICE = [
    {
        format: '7:2',
        index: 11,
    },
    {
        format: '7:3',
        index: 13,
    },
    {
        format: '7:3',
        index: 15,
    },
];

export const WatchService = () => {
    const dispatch = useDispatch();
    const dinamicPage = useTypeSelector(selectDinamicPage);
    const [state, setState] = useState(false);

    useLayoutEffect(() => {
        dispatch(getPage(DynamicPagesKeys.WATCH_SERVICE));
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setState(true);
        }, 300);
    });

    if (!state) {
        return <S.StyledPreloader />;
    }

    return (
        <S.Wrapper>
            <PageTitle>{PageTitles.WATCH_SERVICE}</PageTitle>
            <Breadcrumbs breadcrumbs={WatchServiceBreadcrumbs()} />
            <S.Root>
                {dinamicPage ? (
                    <DynamicPageForm
                        pagesData={dinamicPage}
                        pageKey={DynamicPagesKeys.WATCH_SERVICE}
                        updatePage={updateWatchServicePage}
                        formats={FORMATS_SERVICE}
                    />
                ) : null}
            </S.Root>
        </S.Wrapper>
    );
};
