import { routes } from '../../constants/RouterPath';
import { TypesIcon } from '../../types/TypesIcon';

export interface Option {
    key: number;
    title: string;
    path: string;
    icon?: string;
    childs?: Option[];
    open?: boolean;
}

export const navigationOptions: Option[] = [
    {
        key: 1,
        title: 'Дашборд',
        path: routes.dashboard,
        icon: TypesIcon.DASHBOARD,
    },
    {
        key: 2,
        title: 'Заказы',
        path: routes.orders,
        icon: TypesIcon.ORDERS,
        childs: [
            {
                key: 3,
                title: 'Список заказов',
                path: routes.order_list,
            },
            {
                key: 4,
                title: 'Настройки ',
                path: routes.order_settings,
            },
        ],
    },
    {
        key: 5,
        title: 'Магазины',
        path: routes.shops,
        icon: TypesIcon.SHOPS,
    },
    {
        key: 6,
        title: 'Настройка главной',
        path: routes.home,
        icon: TypesIcon.HOME,
        childs: [
            {
                key: 7,
                title: 'Body сайта',
                path: routes.site_body,
            },
            {
                key: 8,
                title: 'Body мобильного приложения',
                path: routes.mobile_body,
            },
            {
                key: 9,
                title: 'Footer',
                path: routes.footer,
            },
        ],
    },
    {
        key: 10,
        title: 'Товары',
        path: routes.goods,
        icon: TypesIcon.GOODS,
    },
    {
        key: 11,
        title: 'Каталог',
        path: routes.catalog,
        icon: TypesIcon.CATALOG,
        childs: [
            {
                key: 12,
                title: 'Категории',
                path: routes.settings,
            },
            {
                key: 13,
                title: 'Меню',
                path: routes.menu,
            },
            {
                key: 14,
                title: 'Фильтры',
                path: routes.filters,
            },
            // {
            //     key: 15,
            //     title: 'Облако фильтров',
            //     path: routes.filter_cloud,
            // },
            {
                key: 16,
                title: 'Коллекции',
                path: routes.collections,
            },
            {
                key: 17,
                title: 'Бренды',
                path: routes.brands,
            },
            {
                key: 18,
                title: 'Сертификаты',
                path: routes.certificates,
            },
        ],
    },
    // {
    //     key: 19,
    //     title: 'PUSH уведомления',
    //     path: routes.notifications,
    //     icon: TypesIcon.NOTIFICATIONS,
    // },
    {
        key: 20,
        title: 'Страницы сайта',
        path: routes.pages,
        icon: TypesIcon.PAGES,
        childs: [
            {
                key: 21,
                title: 'Скупка и обмен',
                path: routes.purchase_and_exchange,
            },
            {
                key: 22,
                title: 'Рассрочка и кредит',
                path: routes.installment_and_credit,
            },
            {
                key: 23,
                title: 'Оплата и доставка',
                path: routes.payment_and_delivery,
            },
            {
                key: 24,
                title: 'Программа лояльности',
                path: routes.loyalty_program,
            },
            {
                key: 24,
                title: 'Часовой сервисный центр',
                path: routes.watch_service,
            },
        ],
    },
    {
        key: 25,
        title: 'Пользователи',
        path: routes.users,
        icon: TypesIcon.USERS,
    },
    {
        key: 26,
        title: 'Журналирование',
        path: routes.notes,
        icon: TypesIcon.NOTES,
    },
    /* {
        key: 27,
        title: 'Логирование',
        path: routes.logs,
        icon: TypesIcon.LOGS,
    }, */
    {
        key: 28,
        title: 'Справочник',
        path: routes.handbook,
        icon: TypesIcon.MARKER,
    },
    {
        key: 29,
        title: 'Акции',
        path: routes.sales,
        icon: TypesIcon.SALES,
    },
    {
        key: 30,
        title: 'Блог',
        path: routes.blog,
        icon: TypesIcon.BLOG,
    },
    {
        key: 31,
        title: 'Посадочные страницы',
        path: routes.search_settings,
        icon: TypesIcon.NAV_SEARCH,
    },
    {
        key: 32,
        title: 'robots.txt',
        path: routes.robots,
        icon: TypesIcon.ROBOTS,
    },
    {
        key: 33,
        title: 'Доп. настройки',
        path: routes.docs,
        icon: TypesIcon.DOCS,
    },
];
