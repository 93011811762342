import { routes } from '../RouterPath';
import { BreadcrumbsModel } from '../../types/Breadcrumbs/BreadcrumbsModel';

export const WatchServiceBreadcrumbs = () : BreadcrumbsModel[] => ([
    {
        key: '1',
        value: 'Страницы сайта',
        url: routes.pages,
    },
    {
        key: '2',
        value: 'Часовой сервисный центр',
        url: '',
    },
]);
