export enum DynamicPagesKeys {
    INSTALLMENT_AND_CREDIT = 'installment-and-credit',
    PURCHASE_AND_EXCHANGE = 'purchase-and-exchange',
    LOYALTY_PROGRAM = 'loyalty-program',
    WATCH_SERVICE = 'watch-service',
    PAYMENT_AND_DELIVERY_CHASI = 'payment-and-delivery-chasi',
    PAYMENT_AND_DELIVERY_YUV = 'payment-and-delivery-yuv',
    FOOTER_APP = 'footer-app',
    FOOTER_PAYSYSTEM = 'footer-paysystem',
    FOOTER_CONTACTS = 'footer-contacts',
    FOOTER_INFO = 'footer-info',
  }
